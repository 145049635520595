<template>
  <div class="news-card">
    <div class="content banner">
      <div class="left">
        <el-carousel :interval="2000" arrow="always">
          <el-carousel-item>
            <img
              width="100%"
              src="https://dummyimage.com/145x145/96ce54"
              alt=""
              sizes=""
              srcset=""
            />
          </el-carousel-item>
          <el-carousel-item>
            <img
              width="100%"
              src="https://dummyimage.com/145x145/f05654"
              alt=""
              sizes=""
              srcset=""
            />
          </el-carousel-item>

          <el-carousel-item>
            <img
              width="100%"
              src="https://dummyimage.com/145x145/1bd1a5"
              alt=""
              sizes=""
              srcset=""
            />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="right">
        <!-- 带图资讯 -->
        <div class="short-img-news">
          <div class="box" v-for="i in 4" :key="i">
            <img src="https://dummyimage.com/145x145/00bc12" alt="" srcset="" />

            <div class="short-title">
              36氪首发｜无线充电方案渗透进机器人市场，「楚山创新」获数千万元A+轮融资
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="content">
      <div class="left">
        <div class="title">最新文章</div>

        <div class="news-box" v-for="i in 6" :key="i">
          <div class="left">
            <img src="http://dummyimage.com/215x145/50B347" alt="" srcset="" />
          </div>
          <div class="right">
            <div class="top">
              <div class="news-title">
                9点1氪：李宁客服回应奥运冠军被运动鞋划伤；比心App将永久性关闭
                “陪玩”功能；“货拉拉女乘客坠亡案”一审开庭宣判
              </div>
              <div class="news-desc">
                奥运冠军陈雨菲全运会比赛脚趾被运动鞋划伤，李宁客服：将鉴定。
              </div>
            </div>
            <div class="bottom">
              <span> 来自主题：早晚报 | 36氪的朋友们 </span>
              <span>
                <i class="el-icon-time"></i>
                19分钟前</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="title">最新快讯</div>

        <!-- 带图资讯 -->
        <div class="short-img-news" v-if="false">
          <div class="box" v-for="i in 4" :key="i">
            <img src="https://dummyimage.com/145x145/00bc12" alt="" srcset="" />

            <div class="short-title">
              36氪首发｜无线充电方案渗透进机器人市场，「楚山创新」获数千万元A+轮融资
            </div>
          </div>
        </div>

        <!-- 不带图资讯 -->
        <div class="short-news">
          <div class="box" v-for="i in 4" :key="i">
            <div class="news-title" @click="open[i] = !open[i]">
              中国移动百亿规模光缆集采启动，行业供需结构有望改善
            </div>
            <div class="news-time">1小时前</div>
            <div class="news-desc" v-show="open[i]">
              中国移动近日公告2021年-2022年普通光缆集中采购项目启动。
              本次集采普通光缆预估规模为447.05万皮长公里，
              折合1.432亿芯公里，最高投标限价98.59亿元，
              集采规模相比2020年提升20%，比2019年提升36%。
              长飞光纤等上市公司人士表示，此次中国移动集采透露出需求提升，
              未来行业供需结构有望进一步改善。（中证网）
            </div>

            <span class="news-dot"></span>
          </div>

          <div class="more-btn-wrap">
            <el-button style="width: 100%">查看更多</el-button>
          </div>
        </div>

        <div class="title">推荐直播</div>

        <div class="live-list">
          <div class="live-box" v-for="i in 2" :key="i">
            <div class="poster">
              <img
                src="https://dummyimage.com/300x170/c0ebd7"
                alt=""
                srcset=""
              />
            </div>
            <div class="desc">
              <div class="name">
                9月16日，北京·康莱德酒店，36氪将举办第二届「游戏产业创新趋势论坛」。
              </div>
              <div class="time">
                开播时间
                <span class="vline"></span>
                09-16 09:00
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="item-list">
      <div class="title">
        <span>创投机构</span>
        <span>更多</span>
      </div>

      <div class="agency-step">
        <div class="agency-step-left">简单五步</div>
        <div class="agency-step-right">即可对接优质创投机构</div>
      </div>

      <div class="agency-step-list">
        <ul>
          <li>
            <div class="num">1</div>
            <article>免费注册认证</article>
            <aside></aside>
          </li>
          <li>
            <div class="num">2</div>
            <article>一键投递项目</article>
            <aside></aside>
          </li>
          <li>
            <div class="num">3</div>
            <article>海量创投资源极速匹配</article>
            <aside></aside>
          </li>
          <li>
            <div class="num">4</div>
            <article>线下直约优质创投机构</article>
            <aside></aside>
          </li>
          <li>
            <div class="num">5</div>
            <article>项目接洽合作</article>
          </li>
        </ul>
      </div>

      <div class="top-items">
        <el-row :gutter="15">
          <el-col :span="8" v-for="i in 3" :key="i">
            <div class="item-box">
              <div class="investor-info">
                <div class="avatar">
                  <img
                    src="https://dummyimage.com/80x80/44cef6"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="basic-info">
                  <div class="name">孙先生 <span>投资经理</span></div>
                  <div class="co-name">深圳某某某公司</div>
                  <div class="area">
                    <span>关注地区：</span>
                    北京,重庆,上海,四川省,浙江省
                  </div>
                  <div class="ly">
                    <span>关注行业：</span>
                    互联网,大数据,VR/AR/MR制造,生命制药
                  </div>
                </div>
              </div>

              <div class="hot-count">
                <i class="el-icon-brush"></i>
                创投热度
                <span>288</span>
              </div>

              <div class="btn-wrap">
                向TA投递项目
                <i class="el-icon-arrow-right"></i>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

    <div class="item-express">
      <div class="left">
        <div class="title">
          项目速递
          <span>
            更多
            <i class="el-icon-arrow-right"></i>
          </span>
        </div>

        <div class="table-wrap">
          <table>
            <thead>
              <tr>
                <td>创业项目</td>
                <td>所处状态</td>
                <td>所属行业</td>
                <td>所属地区</td>
                <td>刷新时间</td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="i in 4" :key="i">
                <td>安徽石灰岩+碳酸钙矿企业收购项目</td>
                <td>
                  <i class="el-icon-pie-chart"></i>
                  <span>初创期</span>
                </td>
                <td>
                  <span>食品</span>
                  <span>批发零售</span>
                </td>
                <td>
                  <span>
                    <i class="el-icon-location-outline"></i>
                    安徽省
                  </span>
                </td>
                <td>
                  <span>2021-09-23</span>
                </td>
                <td>
                  <span class="btn"> 约谈创业者 </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="right">
        <div class="title">
          创投动态
          <span>
            更多
            <i class="el-icon-arrow-right"></i>
          </span>
        </div>

        <div class="banner">
          <div class="banner-title">发布项目</div>
          <div class="desc">提交项目信息 对接创投机构</div>

          <span class="btn">
            <i class="el-icon-arrow-right"></i>
          </span>
        </div>

        <div class="publish-list">
          <div class="publish-box" v-for="i in 6" :key="i">
            北京市某公司正在寻求
            <span>VR/AR/MR制造,人工智能,机器人</span>
            等方向项目
          </div>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
export default {
  name: "",

  data() {
    return {
      open: {
        1: false,
        2: false,
        3: false,
        4: false,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.news-card {
  background: #f9f9f9;
  // margin-bottom: 50px;
  .content {
    width: 1200px;
    margin: 0 auto;

    padding: 20px 0;

    display: flex;
    align-items: flex-start;

    .left {
      flex: 1 1 auto;
      margin-right: 15px;

      .title {
        font-size: 24px;
        line-height: 33px;
        color: #262626;
        padding-left: 10px;
      }

      .news-box {
        cursor: pointer;
        padding: 15px;
        padding-left: 10px;

        display: flex;
        transition: all 0.2s ease-in;

        &:hover,
        &:active {
          box-shadow: 0 0 7px 0 rgb(0 0 0 / 15%);
          background: #fff;

          .left {
            img {
              transform: scale(1.2);
            }
          }
        }

        .left {
          height: 100%;
          flex: 0 0 auto;
          width: 215px;
          height: 145px;
          overflow: hidden;

          img {
            transition: all 0.8s ease-in;
          }
        }

        .right {
          flex: 1 1 auto;

          display: flex;
          flex-direction: column;

          justify-content: space-between;

          .news-title {
            margin-top: 8px;
            max-height: 48px;
            line-height: 24px;
            overflow: hidden;

            font-size: 18px;
            color: #262626;
            text-align: justify;

            word-break: break-all;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
          }

          .news-desc {
            margin-top: 10px;
            font-size: 13px;
            color: #787878;
            line-height: 20px;
            letter-spacing: 0;
            text-align: justify;
            max-height: 40px;
            overflow: hidden;

            word-break: break-all;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
          }

          .bottom {
            display: flex;
            justify-content: space-between;

            padding-right: 10px;
            font-size: 12px;
            color: #a7a7a7;
          }
        }
      }
    }

    .right {
      flex: 0 0 auto;
      width: 300px;

      .title {
        font-size: 20px;
        color: #262626;
        padding-left: 4px;
        margin-bottom: 20px;
      }

      // 带图片的短讯
      .short-img-news {
        cursor: pointer;
        display: flex;
        flex-wrap: wrap;

        .box {
          position: relative;
          width: 145px;
          height: 145px;
          overflow: hidden;
          margin-right: 10px;
          margin-bottom: 10px;
          background-image: linear-gradient(
            -180deg,
            hsla(0, 0%, 74.9%, 0),
            rgba(0, 0, 0, 0.78) 97%
          );

          img {
            transition: all 0.5s;
          }

          &:active,
          &:hover {
            img {
              transform: scale(1.2);
            }

            .short-title {
              bottom: 72px;
              -webkit-line-clamp: 4;
              max-height: 72px;
              transform: translateY(50%);
            }
          }
        }

        .box:nth-child(2n) {
          margin-right: 0;
        }

        .short-title {
          color: #fff;
          position: absolute;
          bottom: 6px;
          font-size: 12px;

          line-height: 18px;
          max-height: 36px;
          padding: 0 10px;

          word-break: break-all;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;

          transition: all 0.5s;
        }
      }

      // 不带图片的短讯
      .short-news {
        margin-top: 20px;
        margin-bottom: 20px;

        margin-left: 4px;
        border-left: 2px solid #ededed;

        .box {
          padding-left: 15px;
          padding-bottom: 16px;
          position: relative;

          .news-title {
            font-size: 14px;
            color: #333;
            line-height: 22px;
            max-height: 44px;
            overflow: hidden;
            text-align: justify;
            cursor: pointer;
          }

          .news-time {
            padding-top: 7px;
            font-size: 12px;
            color: #a7a7a7;
          }

          .news-desc {
            padding-top: 10px;
            font-size: 13px;
            color: #787878;
            line-height: 22px;
            text-align: justify;
          }

          .news-dot {
            display: inline-block;
            background: #fff;
            width: 12px;
            height: 12px;
            border: 4px solid #b3ccf4;
            border-radius: 100%;
            position: absolute;
            top: 5px;
            left: -7px;
            z-index: 1;
          }
        }

        .more-btn-wrap {
          padding-left: 15px;
          padding-bottom: 15px;
        }
      }

      // 直播推荐
      .live-list {
        margin: 20px 0;

        .live-box {
          cursor: pointer;

          &:active,
          &:hover {
            .poster {
              img {
                transform: scale(1.2);
              }
            }
          }

          & + .live-box {
            margin-top: 15px;
          }

          .poster {
            width: 300px;
            height: 170px;
            overflow: hidden;

            img {
              transition: all 0.2s;
            }
          }

          .desc {
            font-size: 14px;
            background: #f1f3f4;
            padding: 10px;

            .name {
              font-weight: bold;
              word-break: break-all;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
            }

            .time {
              font-weight: bold;
              color: #4285f4;
              font-size: 12px;
              margin-top: 4px;

              .vline {
                display: inline-block;
                width: 1px;
                height: 12px;
                vertical-align: middle;
                background-color: #4285f4;
                margin: 0 4px;
              }
            }
          }
        }
      }
    }
  }

  .item-list {
    width: 1200px;
    margin: 0 auto;
    .title {
      font-size: 24px;
      font-weight: bold;
      color: #111111;
      display: flex;
      padding-top: 35px;
    }

    .agency-step {
      margin-top: 30px;
      width: 330px;
      height: 50px;
      background: url("../../assets/images/ori/ct_title.png");
      display: flex;
      font-size: 18px;
      color: #4860d3;
      font-weight: bolder;

      .agency-step-left {
        width: 108px;
        text-align: center;
        line-height: 45px;
      }

      .agency-step-right {
        flex: 1;
        text-align: center;
        line-height: 45px;
      }
    }

    .agency-step-list {
      padding: 25px 0;
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: space-between;

        li {
          height: 70px;
          background: url(../../assets/images/ori/ctjg_num.png) no-repeat;
          padding-left: 24px;
          display: flex;
          align-items: center;
          margin-right: 5px;

          .num {
            font-size: 36px;
            color: #cfd0e6;
            font-style: italic;
            margin-right: 5px;
          }

          article {
            font-size: 20px;
            color: #111111;
            margin-right: 5px;
          }
        }
      }
    }

    .top-items {
      padding-bottom: 40px;
      .item-box {
        background: #fff;
        position: relative;
        padding-top: 40px;

        .investor-info {
          .avatar {
            position: absolute;
            top: 40px;
            left: 0;
            width: 130px;
            height: 80px;
            text-align: center;
            overflow: hidden;

            img {
              width: 80px;
              height: 80px;
              overflow: hidden;
              border-radius: 50%;
            }
          }

          .basic-info {
            padding-left: 130px;

            .name {
              font-size: 16px;
              color: #000;
              height: 25px;
              line-height: 25px;
              font-weight: bold;

              span {
                color: #666;
                font-weight: normal;
                margin-left: 10px;
              }
            }

            .co-name {
              font-size: 14px;
              color: #666666;
            }

            .area,
            .ly {
              padding-top: 12px;
              padding-right: 20px;
              font-size: 14px;

              line-height: 20px;

              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;

              span {
                color: #999999;
              }
            }

            .ly {
              padding-top: 0;
            }
          }
        }

        .hot-count {
          position: absolute;
          top: 18px;
          right: 34px;
          height: 18px;
          font-size: 12px;
          color: #666;

          i {
            color: red;
          }

          span {
            font-size: 14px;
            color: #ff5f32;
            margin-left: 6px;
            font-weight: bold;
          }
        }

        .btn-wrap {
          cursor: pointer;
          text-align: right;
          color: #4860d3;
          padding: 20px 15px 25px;
          font-size: 14px;
        }
      }
    }
  }

  .item-express {
    width: 1200px;
    margin: 0 auto;

    padding-bottom: 40px;

    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .title {
      font-size: 24px;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        cursor: pointer;
        font-size: 14px;
        color: #4860d3;
        font-weight: normal;
      }
    }

    .right {
      flex: 0 0 auto;
      width: 330px;
      margin-left: 25px;

      .banner {
        height: 100px;
        margin-top: 10px;
        padding-left: 30px;
        background-image: url(../../assets/images/ori/home048.png);
        background-repeat: no-repeat;
        background-size: cover;

        color: #fff;

        position: relative;

        .banner-title {
          font-size: 20px;
          font-weight: bold;
          line-height: 24px;
          padding-top: 25px;
        }

        .desc {
          font-size: 14px;
          line-height: 18px;
          padding-top: 12px;
        }

        .btn {
          cursor: pointer;
          position: absolute;
          width: 41px;
          height: 41px;
          top: 33px;
          right: 30px;
          line-height: 41px;
          text-align: center;

          border: 1px solid #fff;
          border-radius: 50%;
          font-size: 24px;

          &:hover,
          &:active {
            background: #fff;
            color: #4860d3;
          }
        }
      }

      .publish-list {
        margin: 20px 0;

        .publish-box {
          margin-left: 8px;
          padding-bottom: 15px;
          border-left: 1px solid #ededed;
          padding-left: 15px;
          font-size: 14px;

          // & + .publish-box {

          // }
        }
      }
    }

    .left {
      flex: 1 1 auto;

      .table-wrap {
        table {
          width: 100%;

          thead {
            height: 86px;
            line-height: 86px;
            font-size: 16px;
            color: #111111;
            font-weight: bold;
          }

          td:nth-child(1) {
            padding-left: 20px;
            width: 300px;

            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          tbody {
            tr {
              border-top: 1px solid #ededed;
            }

            td {
              padding: 25px 0;
              font-size: 14px;
              color: #666;
            }

            td:nth-child(1) {
              color: #000;
              font-size: 16px;
              font-weight: bold;
              line-height: 24px;
            }

            td:nth-child(2) {
              i {
                color: #4860d3;
                margin-right: 5px;
              }
            }

            td:nth-child(3) {
              text-align: center;
              span {
                width: 100px;
                padding: 0 10px;
                border: 1px solid #4860d3;
                color: #4860d3;
                font-size: 12px;
                margin-bottom: 6px;
                display: block;
                overflow: hidden;
                text-align: center;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }

            td:nth-child(4) {
              span {
                i {
                  color: #4860d3;
                }
              }
            }

            td:last-child {
              cursor: pointer;
              span {
                display: inline-block;
                background: #4860d3;
                color: #fff;
                height: 25px;
                line-height: 25px;
                padding: 0 8px;
                border-radius: 2px;

                &:hover,
                &:active {
                  background: #ff7356;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>