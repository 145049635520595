<template>
  <div class="home">
    <news-card />
  </div>
</template>

<script>
import NewsCard from "../../../components/ori-com/NewsCard.vue";
export default {
  components: { NewsCard },
};
</script>

<style>
</style>